import React from 'react'

import Header from '../components/header'
import Footer from '../components/footer'
import Features from '../components/features'
import Hero from '../components/hero'
import Description from '../components/description'
// import Testimonial from '../components/testimonial'

import {
  VisbilityContainer,
  CommonPose
} from '../components/animations'

const Pricing = props => (
  <VisbilityContainer>
    <div className="pricing" id="pricing">
      <div className="container">
        <CommonPose>
          <h2>
            Pricing made simple. <br />
            One price. Unlimited potential.
          </h2>
        </CommonPose>
        <CommonPose>
          <h3>
            One low monthly fee let's you evolve your business and start saving
            time and money on your billing function. Here's the deal.
          </h3>
        </CommonPose>
        <CommonPose className="pricing-box">
          <div className="col1">
            <div>
              <sup>$</sup><strong>50</strong><br />
              / month
            </div>

            <a href={process.env.SIGNUP_URL} className="btn btn-white">Sign Up</a>
          </div>
          <div className="col2">
            <ul>
              <li>Unlimited client accounts</li>
              <li>1 Indepdent Account</li>
              <li>One-Time Invoicing</li>
              <li>Recurring Auto-Billing</li>
              <li>Credit/Debit Card Payments</li>
            </ul>
          </div>
        </CommonPose>
      </div>
    </div>
  </VisbilityContainer>
)

export default function Home() {
  return (
    <>
      <VisbilityContainer>
        <Header />
        <Hero />
      </VisbilityContainer>
      <Description />
      <Features />
      <Pricing />
      {/*
      <Testimonial />
      */}
      <Footer />
    </>
  )
}
