import React from 'react'
import Typewriter from 'typewriter-effect'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { CommonPose } from '../components/animations'

const Card = ({ title, description, fluid }) => (
  <CommonPose className="feature">
    <Img alt={title} fluid={fluid} />
    <h2>{ title }</h2>
    <p>{ description }</p>
  </CommonPose>
)

const Hero = props => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        contentfulHome
        {
          verb1
          verb2
          verb3
          tagline
          shortDescription

          card1Title
          card1Description
          card1Image {
            fluid(maxWidth: 200, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_withWebp
            }
          }

          card2Title
          card2Description
          card2Image {
            fluid(maxWidth: 200, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_withWebp
            }
          }

          card3Title
          card3Description
          card3Image {
            fluid(maxWidth: 200, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_withWebp
            }
          }

          pricingTagline
        }
      }
    `}
    render={ data => {
      const { verb1, verb2, verb3, tagline, shortDescription, pricingTagline } = data.contentfulHome
      const { card1Title, card1Description, card1Image  } = data.contentfulHome
      const { card2Title, card2Description, card2Image  } = data.contentfulHome
      const { card3Title, card3Description, card3Image  } = data.contentfulHome
      return (
        <div className="hero">
          <div className="hero-wrapper">
            <video autoPlay muted loop>
                <source src="https://videos.ctfassets.net/c5i9di5ck13f/56IjVFwr44z3kKaiFxI5SE/11f174a31e7f04dea6e5649a4900ef15/ChalicePay_background_video_V2.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
            </video>
            <div className="container" style={{ zIndex: 999 }}>
              <div className="heroText">
                <CommonPose>
                  <h1>
                    <Typewriter
                      options={{
                        cursor: '_',
                        cursorClassName: 'hero-cursor',
                        strings: [verb1, verb2, verb3],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </h1>
                </CommonPose>
                <CommonPose>
                  <h2>{ tagline }</h2>
                </CommonPose>
                <CommonPose className="hero-subtitle">
                  <div className="col1">
                    { shortDescription }
                  </div>
                  <div className="col2">
                    <a href={process.env.SIGNUP_URL} className="btn btn-blue">Sign Up</a>
                  </div>
                </CommonPose>
              </div>
            </div>
          </div>
          <div className="hero-details container">
            <Card title={card1Title} description={card1Description} fluid={card1Image.fluid} />
            <Card title={card2Title} description={card2Description} fluid={card2Image.fluid} />
            <Card title={card3Title} description={card3Description} fluid={card3Image.fluid} />
          </div>
          <div className="hero-pricing container">
            <div className="c1">
              { pricingTagline }
            </div>
            <div className="c2">
              <sup>$</sup><strong>50</strong>/month
            </div>
          </div>
        </div>
      )
    }} />
)

export default Hero
